<template>
  <div class="card_warp">
    <div :style="{'height':$route.query.selfcCorrect && judge_status?'100vh':'85vh','overflow':'auto'}">
      <p>
        答题情况：
      </p>
      <div class="cards">
        <div class="card"
             @click="checkTopic(index)"
             v-for="(item,index) in info.volist"
             :key="index">
          <span :class="getClass(item)">{{item.user_answer||index + 1}}</span>
        </div>
      </div>
      <p>图片: </p>
      <div class="img_warp">
        <div class="image"
             v-for="(item,index) in info.images"
             :key="item">
          <a-image :z-index="index"
                   :src="addHeader(item)">
          </a-image>
        </div>
      </div>
    </div>
    <!-- {{judge_status}} -->
    <div class="submit"
         v-if="$route.query.selfcCorrect && !judge_status"
         @click="submit">
      提交</div>
    <GenneralCommont ref="GenneralCommontRef" />
  </div>
</template>

<script setup>
import GenneralCommont from './components/generalCommont.vue'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { addHeader } from '@/init/index.js'

let router = useRouter()
let info = ref({})
let judge_status = ref(0)
let GenneralCommontRef = ref(null)
onMounted(() => {
  let info1 = JSON.parse(window.sessionStorage.getItem('myTestPaperAnalsis'))
  judge_status.value = window.sessionStorage.getItem('judge_status')
  info1.volist = info1.volist.map(item => {
    if (item.user_answer) {
      item.answer = item.user_answer.split(',')
    }
    return item
  })
  info.value = info1
})
const getValue = (item, index) => {
  let checkArr = this.$checkArr
  if (checkArr.indexOf(item.topic) != -1) {
    if (item.user_answer && item.user_answer.length && item.user_answer == item.question_answer) {
      return item.user_answer
    } else {
      return index
    }
  } else {
    return 'text'
  }
}
const checkTopic = (index) => {
  window.sessionStorage.setItem('myTestPaperAnalsisIndex', `${index}`)
  router.go(-1)
}
const getClass = (item) => {
  // 如果已经批改
  if (Number(item.user_score) == Number(item.question_score)) {
    return 'active'
  } else if (Number(item.question_score) > Number(item.user_score) && Number(item.user_score) != 0) {
    return 'yiban'
  }
  return 'err'

}
const submit = () => {
  let num = 0
  let checkArr = [1, 12, 14, 15, 25, 26, 27, 34, 38, 40, 43, 46, 47, 48, 54, 56, 65, 67, 69, 70, 71, 78, 81, 98, 99, 100]
  info.value.volist.forEach(item => {
    if (checkArr.indexOf(item.question_type_id) == -1) {
      if (item.isChecked != 1) {
        num += 1
      }
    }
  });
  // this.$confirm(`还有${num}题未批改，是否继续提交?!`, "提示", {
  //   confirmButtonText: "确定",
  //   cancelButtonText: "取消",
  //   type: "warning",
  // }).then(() => {
  //   this.$refs.GenneralCommontRef.dialogVisible = true
  // })
  Modal.confirm({
    title: '提示',
    icon: createVNode(ExclamationCircleOutlined),
    okText: '确定',
    cancelText: '取消',
    content: createVNode('div', { style: '' }, `还有${num}题未批改，是否继续提交?!`),
    onOk () {
      GenneralCommontRef.value.dialogVisible = true
    },
    onCancel () {
      return
      //console.log('Cancel');
    },
  })
}
// export default {
//   data () {
//     return {
//       info: {},
//       judge_status: 0
//     }
//   },
//   beforeRouteLeave (to, from, next) {
//     if (to.name == '/mytestpaper') {
//       to.meta.keepAlive = false;  // 刷新
//     }
//     next();
//   },
//   mounted () {
//     let info = JSON.parse(window.sessionStorage.getItem('myTestPaperAnalsis'))
//     this.judge_status = window.sessionStorage.getItem('judge_status')
//     info.volist = info.volist.map(item => {
//       if (item.user_answer) {
//         item.answer = item.user_answer.split(',')
//       }
//       return item
//     })
//     this.info = info
//   },
//   components: {
//     GenneralCommont
//   },
//   methods: {

//     getValue (item, index) {
//       let checkArr = this.$checkArr
//       if (checkArr.indexOf(item.topic) != -1) {
//         if (item.user_answer && item.user_answer.length && item.user_answer == item.question_answer) {
//           return item.user_answer
//         } else {
//           return index
//         }
//       } else {
//         return 'text'
//       }
//     },
//     checkTopic (index) {
//       window.sessionStorage.setItem('myTestPaperAnalsisIndex', `${index}`)
//       this.$router.go(-1)
//     },
//     getClass (item) {
//       // 如果已经批改
//       if (Number(item.user_score) == Number(item.question_score)) {
//         return 'active'
//       } else if (Number(item.question_score) > Number(item.user_score) && Number(item.user_score) != 0) {
//         return 'yiban'
//       }
//       return 'err'

//     },
//     submit () {
//       let num = 0
//       let checkArr = [1, 12, 14, 15, 25, 26, 27, 34, 38, 40, 43, 46, 47, 48, 54, 56, 65, 67, 69, 70, 71, 78, 81, 98, 99, 100]
//       this.info.volist.forEach(item => {
//         if (checkArr.indexOf(item.question_type_id) == -1) {
//           if (item.isChecked != 1) {
//             num += 1
//           }
//         }
//       });
//       // this.$confirm(`还有${num}题未批改，是否继续提交?!`, "提示", {
//       //   confirmButtonText: "确定",
//       //   cancelButtonText: "取消",
//       //   type: "warning",
//       // }).then(() => {
//       //   this.$refs.GenneralCommontRef.dialogVisible = true
//       // })
//       Modal.confirm({
//         title: '提示',
//         icon: createVNode(ExclamationCircleOutlined),
//         okText: '确定',
//         cancelText: '取消',
//         content: createVNode('div', { style: '' }, `还有${num}题未批改，是否继续提交?!`),
//         onOk () {
//           this.$refs.GenneralCommontRef.dialogVisible = true
//         },
//         onCancel () {
//           return
//           //console.log('Cancel');
//         },
//       })
//     }

//   },
// }
</script>

<style lang="scss" scoped>
.card_warp {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
  p {
    margin-top: 15px;
    text-indent: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .cards {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .card {
      width: 75px;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #666666;
      font-weight: bold;
      font-size: 18px;
      margin-right: 1.98%;
      margin-bottom: 5px;
      margin-top: 10px;
      background: #f6f6f6;
      border-radius: 5px;
    }
  }
  .img_warp {
    display: flex;
    margin-left: 20px;
    margin-top: 19px;
    .image {
      position: relative;
      width: 75px;
      height: 75px;
      margin-right: 20px;
      justify-content: center;
      .el-image {
        border-radius: 5px;
        width: 75px;
        height: 75px;
      }
      .close {
        position: absolute;
        right: -5px;
        top: -5px;
        background: red;
        color: white;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8px;
      }
    }
    .add_img {
      width: 75px;
      height: 75px;
      font-size: 14px;
      font-weight: 500;
      background: #f6f6f6;
      border-radius: 5px;
      text-align: center;
      color: #2196f3;
      img {
        width: 25px;
        height: 21px;
        margin-top: 19px;
        margin-bottom: 6px;
      }
    }
  }
  .btn_warp {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    .btn {
      display: flex;
      width: 920px;
      height: 44px;
      justify-content: center;
      align-items: center;
      color: white;
      background: #2196f3;
      border-radius: 5px;
      margin: 0 auto;
    }
  }
}

.active {
  background: #ecfcf5 !important;
  color: #0ec3b5;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.err {
  background: #fcecec !important;
  color: #ff6f6f;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.yiban {
  background: #fcf2ec !important;
  color: #ff916f;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.submit {
  position: fixed;
  bottom: 30px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-left: 2.5%;
  background: #2196f3;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}
</style>