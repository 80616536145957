<template>
  <div>
    <a-modal title="总评"
             :visible="dialogVisible"
             :close-on-click-modal="false"
             :append-to-body="true"
             width="300px"
             :before-close="handleClose"
             @ok="save">
      <a-row class="input_Warp">
        <a-textarea placeholder="请输入"
                    :rows="4"
                    style="width:260px"
                    v-model:value="user_paper_comment" />
      </a-row>

      <!-- <div class="btn_warp">
        <a-button type="primary"
                  @click="save">确定</a-button>
      </div> -->
    </a-modal>
  </div>
</template>

<script>
import { instance } from '@/utils/http.js'

export default {
  data () {
    return {
      dialogVisible: false,
      user_paper_comment: ''
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    save () {
      let info = JSON.parse(window.sessionStorage.getItem('myTestPaperAnalsis'))
      var compact = info.volist.map(item => {
        return {
          user_answer: item.user_answer,
          comment_to_user: item.comment_to_user,
          user_score: item.user_score,
          user_paper_detail_id: item.user_paper_detail_id,
        }
      })

      let form = {
        user_paper_id: window.sessionStorage.getItem('selfCorrectId'),
        user_paper_comment: this.user_paper_comment,
        compact,
        submit: 1
      }
      instance({
        url: '/api/v1/my/correct_self',
        method: 'post',
        data: form
      }).then(res => {
        this.handleClose()
        window.sessionStorage.removeItem('myTestPaperAnalsis')
        window.sessionStorage.removeItem('myTestPaperAnalsisIndex')
        window.sessionStorage.removeItem('selfCorrectId')
        this.$router.go(-2)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23px;
  font-size: 16px;
  padding: 27px;
  padding-top: 0;
}
.btn_warp {
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
::v-deep .a-dialog__body {
  padding: 0;
}

::v-deep .a-dialog__header {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
::v-deep .a-dialog {
  border-radius: 10px;
  margin-top: 30vh !important;
}
.input_Warp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>